import React, { useState } from 'react'
import { Grid, Flex, Button, Text, Box, Spinner } from '@chakra-ui/react'
import { FaCcVisa, FaCcMastercard } from 'react-icons/fa'
import { SubscriptionForm } from './subscription-form'

const ICON_MAP = {
  visa: FaCcVisa,
  mastercard: FaCcMastercard,
}

const ChangeSubscriptionPaid = ({
  currentPriceId,
  user,
  onCancel,
  onSubscribe,
  isUpdating,
}) => {
  const [showForm, setShowForm] = useState(false)

  const handleClick = () => {
    onSubscribe({
      payload: {
        subscriptionId: user.subscription.id,
        priceId: currentPriceId,
      },
      url: '/v1/billing/update-subscription',
    })
  }

  const { hasPaymentMethod, payment } = user
  const showSubscriptionForm = !hasPaymentMethod || showForm
  const showUseOldPayment = hasPaymentMethod && !showForm

  return (
    <Flex flexDirection="column">
      {showUseOldPayment && (
        <Flex alignItems="center" flexDirection="column">
          <Text fontWeight="medium" mb="5">
            Use existing payment method:
          </Text>
          <Grid columnGap={3} templateColumns="70px 1fr">
            <Box as={ICON_MAP[payment.type]} size="64px" />

            <Box alignSelf="center">
              <Text>{payment.last4}</Text>

              <Text>
                Exp. {payment.exp_month}/{payment.exp_year}
              </Text>
            </Box>
          </Grid>
          <Flex alignItems="center" justifyContent="center" width="50%">
            <Flex borderBottom="1px solid black" width="100%" />
            <Text
              fontSize="lg"
              fontWeight="bold"
              letterSpacing="wide"
              my="5"
              px="4"
            >
              OR
            </Text>
            <Flex borderBottom="1px" width="100%" />
          </Flex>
          <Button
            onClick={() => {
              setShowForm(true)
            }}
            variant="outline"
          >
            Add new payment method
          </Button>
          {isUpdating ? (
            <Flex alignItems="center" mt="8" py="3">
              <Text mr="4">Updating subscription...</Text>
              <Spinner size="lg" />
            </Flex>
          ) : (
              <Grid
                alignSelf="flex-end"
                columnGap={3}
                mt="8"
                py="3"
                templateColumns="1fr 1fr"
              >
                <Button onClick={onCancel} variant="ghost">
                  Cancel
              </Button>
                <Button onClick={handleClick}>Subscribe</Button>
              </Grid>
            )}
        </Flex>
      )}

      {showSubscriptionForm && (
        <SubscriptionForm
          currentPriceId={currentPriceId}
          onCancel={() => {
            setShowForm(false)
            onCancel()
          }}
          user={user}
        />
      )}
    </Flex>
  )
}

export { ChangeSubscriptionPaid }
