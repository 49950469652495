import React from 'react'
import { Grid, Flex, Button, Text, Spinner } from '@chakra-ui/react'

const ChangeSubscriptionFree = ({
  currentPriceId,
  user,
  onCancel,
  onSubscribe,
  isUpdating,
}) => {
  const handleClick = () => {
    onSubscribe({
      payload: {
        subscriptionId: user.subscription.id,
        priceId: currentPriceId,
      },
      url: '/v1/billing/update-subscription',
    })
  }

  return (
    <Flex flexDirection="column">
      <Text>Are you sure you would like to downgrade your plan?</Text>

      {isUpdating ? (
        <Flex alignItems="center" mt="8" py="3">
          <Text mr="4">Updating subscription...</Text>
          <Spinner size="lg" />
        </Flex>
      ) : (
          <Grid columnGap={3} mt="8" py="3" templateColumns="1fr 1fr">
            <Button onClick={onCancel} variant="ghost">
              Cancel
          </Button>
            <Button onClick={handleClick}>Yes</Button>
          </Grid>
        )}
    </Flex>
  )
}

export { ChangeSubscriptionFree }
