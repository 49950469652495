import React from 'react'
import {
  Grid,
  Flex,
  Text,
  Button,
  Input,
  FormControl,
  FormLabel,
  Select,
} from '@chakra-ui/react'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useMachine } from '@xstate/react'
import { useFormik } from 'formik'
import { fetchMachine, events } from '../../../utils/fetch.machine'
import { request } from '../../../utils/request'
import CountryList from '../../../utils/country-codes'

const updateSubscription = (ctx: any, evt: any) => {
  return request({
    method: 'post',
    url: '/v1/billing/update-subscription',
    data: evt.payload,
  })
}

const SubscriptionForm = ({ currentPriceId, user, onCancel }) => {
  const stripe = useStripe()
  const elements = useElements()

  const [currentState, send] = useMachine(fetchMachine, {
    services: {
      fetchData: updateSubscription,
    },
  })

  const formik = useFormik({
    initialValues: {
      name: '',
      companyName: '',
      taxNumber: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      country: '',
    },
    onSubmit: async (values, actions) => {
      try {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
        })

        console.log('WOOOO')

        if (error) {
          console.log({ error })
        } else {
          const payload = {
            paymentMethodId: paymentMethod?.id,
            subscriptionId: user.subscription.id,
            priceId: currentPriceId,
            stripePayload: {
              ...paymentMethod,
              taxNumber: values.taxNumber,
            },
          }

          send({
            type: events.FETCH,
            payload,
          })
        }
      } catch (error) {
        console.log({ error })
      }
    },
  })

  return (
    <FormControl as="form" onSubmit={formik.handleSubmit}>
      <FormLabel htmlFor="name">Name</FormLabel>
      <Input
        id="name"
        mb={4}
        onChange={formik.handleChange}
        type="text"
        value={formik.values.name}
      />
      <FormLabel htmlFor="addressLine1">Address 1 (optional)</FormLabel>
      <Input
        id="addressLine1"
        mb={4}
        onChange={formik.handleChange}
        value={formik.values.addressLine1}
      />
      <FormLabel htmlFor="addressLine2">Address 2 (optional)</FormLabel>
      <Input
        id="addressLine2"
        mb={4}
        onChange={formik.handleChange}
        value={formik.values.addressLine2}
      />
      <FormLabel htmlFor="city">City (optional)</FormLabel>
      <Input
        id="city"
        mb={4}
        onChange={formik.handleChange}
        value={formik.values.city}
      />
      <FormLabel htmlFor="country">Country (optional)</FormLabel>
      <Select
        id="country"
        mb={4}
        name="country"
        onChange={formik.handleChange}
        placeholder="Select country..."
        value={formik.values.country}
      >
        {CountryList.map(country => {
          return (
            <option key={country.value} value={country.value}>
              {country.label}
            </option>
          )
        })}
      </Select>

      <label>
        <Text fontSize="md" fontWeight="medium" pb="1">
          Card details
        </Text>
        <Flex
          alignItems="center"
          border="1px"
          borderColor="inherit"
          borderRadius="md"
          height="2.5rem"
          px="4"
          sx={{
            '& div:first-child': {
              width: '100%',
            },
          }}
        >
          <CardElement />
        </Flex>
      </label>
      <Grid columnGap={3} mt="8" py="3" templateColumns="1fr 1fr">
        <Button onClick={onCancel} variant="outline">
          Cancel
        </Button>
        <Button type="submit">Subscribe</Button>
      </Grid>
    </FormControl>
  )
}

export { SubscriptionForm }
