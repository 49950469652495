import React from 'react'
import { Grid, Flex, Text, useColorModeValue } from '@chakra-ui/react'

type DashboardCardProps = {
  title: string
  subtitle?: React.ReactNode
  children: React.ReactNode
  mt?: string
}

const DashboardCard = ({
  title,
  subtitle,
  children,
  ...rest
}: DashboardCardProps) => {
  const border = useColorModeValue({}, { borderWidth: '1px' })

  return (
    <Grid borderRadius="sm" shadow="md" {...border} {...rest}>
      <Flex bg="gray.700" justifyContent="space-between" px="4" py="4">
        <Text color="gray.50" fontWeight="bold">
          {title}
        </Text>
        <Text>{subtitle}</Text>
      </Flex>

      <Grid p="4" templateRows="4">
        {children}
      </Grid>
    </Grid>
  )
}

export { DashboardCard }
