import React from 'react'
import {
  Flex,
  Button,
  FormControl,
  FormLabel,
  Input,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import { object, string } from 'yup'
import { useMachine } from '@xstate/react'
import { request } from '../../../utils/request'
import { fetchMachine, events } from '../../../utils/fetch.machine'

const ChangeUserInfoSchema = object().shape({
  email: string().email(),
})

const updateUserInfo = (ctx: any, evt: any) => {
  return request({
    method: 'put',
    url: '/v1/user',
    data: evt.payload,
  })
}

const ChangeUserInfoForm = ({ user, onCancel, onSuccess }) => {
  const [, send] = useMachine(fetchMachine, {
    services: {
      fetchData: updateUserInfo,
    },
    actions: {
      onSuccess,
    },
  })

  const formik = useFormik({
    initialValues: {
      name: user.name,
      email: user.email,
    },
    validationSchema: ChangeUserInfoSchema,
    onSubmit: values => {
      const { name, email } = values

      send({
        type: events.FETCH,
        payload: {
          name,
          email,
        },
      })
    },
  })

  const { errors, touched } = formik

  return (
    <ModalContent>
      <ModalHeader>Edit User Details</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <FormControl as="form" onSubmit={formik.handleSubmit}>
          <FormLabel htmlFor="name">Name</FormLabel>
          <Input
            id="name"
            mb={4}
            onChange={formik.handleChange}
            value={formik.values.name}
            variant="flushed"
          />
          <FormLabel htmlFor="email">Email</FormLabel>
          <Input
            id="email"
            isInvalid={Boolean(errors.email && touched.email)}
            mb={4}
            onChange={formik.handleChange}
            type="email"
            value={formik.values.email}
            variant="flushed"
          />

          <Flex justifyContent="flex-end" py="3">
            <Button onClick={onCancel} variant="ghost">
              Cancel
            </Button>
            <Button ml="4" type="submit">
              Confirm
            </Button>
          </Flex>
        </FormControl>
      </ModalBody>
    </ModalContent>
  )
}

export { ChangeUserInfoForm }
