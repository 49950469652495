import React from 'react'
import { Box, Grid } from '@chakra-ui/react'
import { Router } from '@reach/router'
import Layout from '../../components/layout'
import { PrivateRoute } from '../../components/private-route'
import { Dashboard } from '../../features/dashboard/home'
import { QueryHelper } from '../../features/dashboard/query-helper'
import { AccessKeys } from '../../features/dashboard/access-keys'
import { GettingStarted } from '../../features/dashboard/getting-started'
import { SubscriptionPlanView } from '../../features/dashboard/subscription-plans'
import { SideNav } from '../../components/side-nav'

const links = [
  {
    title: 'Getting Started',
    href: '/dashboard/getting-started',
  },
  {
    title: 'Dashboard',
    href: '/dashboard',
  },
  {
    title: 'Screenshot Builder',
    href: '/dashboard/screenshot-builder',
  },
  {
    title: 'Access Keys',
    href: '/dashboard/access-keys',
  },
  {
    title: 'Change Subscription',
    href: '/dashboard/plans',
  },
]

const HomePage = props => {
  return (
    <Layout>
      <Grid
        maxWidth="6xl"
        px="8"
        templateColumns={['1fr', '1fr', '1fr', '15rem 1fr']}
        width="100%"
      >
        <SideNav
          currentRoute={props.location.pathname}
          flexDirection={['row', 'row', 'row', 'column']}
          flexWrap="wrap"
          isGatsbyLink
          links={links}
          mb="6"
        />
        <Box justifyItems="stretch" width="100%">
          <Router basepath="dashboard">
            <PrivateRoute component={GettingStarted} path="getting-started" />
            <PrivateRoute component={QueryHelper} path="screenshot-builder" />
            <PrivateRoute component={AccessKeys} path="access-keys" />
            <PrivateRoute component={SubscriptionPlanView} path="plans" />
            <PrivateRoute component={Dashboard} path="/" />
          </Router>
        </Box>
      </Grid>
    </Layout>
  )
}

export default HomePage
