import React from 'react'
import {
  Box,
  Grid,
  Flex,
  Text,
  Divider,
  useColorModeValue,
} from '@chakra-ui/react'
import { FaCheck } from 'react-icons/fa'

type SubscriptionPlan = {
  planId: string
  price: number
  reasons: string[]
  title: string
}

type PlanCardProps = {
  plan: SubscriptionPlan
  children?: React.ReactNode
}

export const PlanCard = ({ plan, children }: PlanCardProps) => {
  const border = useColorModeValue({}, { borderWidth: '1px' })

  return (
    <Flex
      key={plan.planId}
      borderRadius="sm"
      flexDirection="column"
      shadow="md"
      {...border}
    >
      <Flex
        alignItems="center"
        bg="gray.700"
        height="55px"
        justifyContent="center"
        p="4"
      >
        <Text color="gray.50" fontWeight="bold">
          {plan.title}
        </Text>
      </Flex>

      <Flex
        alignSelf="stretch"
        fontSize="xl"
        height="50px"
        justifyContent="center"
        mt="4"
      >
        <Text alignSelf="center" as="sup">
          $
        </Text>
        <Text fontSize="4xl" fontWeight="bold" mx="1">
          {plan.price}
        </Text>
        <Text alignSelf="center">/ month</Text>
      </Flex>

      <Grid height="150px" justifyItems="center" p="4" templateRows="4">
        {plan.reasons.map((reason, idx) => {
          return (
            <Flex
              key={reason}
              alignItems="center"
              height="24px"
              mb="2"
              width={['10rem', '10rem', '13rem']}
            >
              <Box as={FaCheck} color="green.500" mr="3" />
              <Text key={reason} fontWeight={idx === 0 && 'bold'}>
                {reason}
              </Text>
            </Flex>
          )
        })}
      </Grid>
      {children && <Divider my="5" />}
      <Flex alignItems="center" height="100%" justifyContent="center" pb="4">
        {children}
      </Flex>
    </Flex>
  )
}
