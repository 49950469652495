import React from 'react'
import {
  Divider,
  Box,
  Grid,
  Flex,
  Text,
  Button,
  Spinner,
} from '@chakra-ui/react'
import { useMachine } from '@xstate/react'
import { format } from 'date-fns'
import { fetchMachine, events } from '../../utils/fetch.machine'
import { request } from '../../utils/request'
import { useUserService, userEvents } from '../user/user.service'
import SEO from '../../components/seo'
import { DashboardCard } from '../../components/dashboard-card'

const generateNewKey = (ctx: any, evt: any) => {
  const { accessKey } = evt.payload

  return request({
    method: 'post',
    url: '/v1/user/access-key',
    data: { accessKey },
  })
}

const AccessKeys = () => {
  const { user, isLoading, send: updateUser } = useUserService()

  const [currentState, send] = useMachine(fetchMachine, {
    services: {
      fetchData: generateNewKey,
    },
    actions: {
      onSuccess: (ctx, evt) => {
        updateUser(userEvents.CACHE_USER, { payload: evt.data.data })
      },
    },
  })

  const isGeneratingNewKey = currentState.matches('loading')
  const hasError = currentState.matches('error')

  if (isLoading) {
    return null
  }

  const { accessKeys } = user

  return (
    <>
      <SEO title="Access Keys" />
      <DashboardCard title="Access Key">
        <Grid p="4">
          {accessKeys.map(key => {
            return (
              <Box key={key.SK}>
                <Grid gap={6} templateColumns="1fr 1fr" templateRows="1">
                  <Grid>
                    <Text fontSize="sm" fontWeight="bold">
                      {key.SK.replace('ACCESS_KEY#', '')}
                    </Text>
                    <Text color="gray.400" fontSize="sm" mt="2">
                      Created {format(key.createdAt, 'MM/dd/yyyy')}
                    </Text>
                  </Grid>

                  <Flex justifyContent="flex-end">
                    <Text fontWeight="bold" mr="1">
                      {key.used}
                    </Text>{' '}
                    <Text color="gray.600">screenshots taken this period</Text>
                  </Flex>
                </Grid>
                <Divider my="4" />
              </Box>
            )
          })}

          <Flex alignItems="center" justifyContent="flex-end">
            {hasError && (
              <Text color="red.500" mr="6">
                Error: {currentState.context.error}
              </Text>
            )}
            {isGeneratingNewKey ? (
              <Spinner size="lg" />
            ) : (
              <>
                <Button
                  onClick={() => {
                    send({
                      type: events.FETCH,
                      payload: { accessKey: accessKeys[0].SK },
                    })
                  }}
                >
                  Reset Access Key
                </Button>
              </>
            )}
          </Flex>
        </Grid>
      </DashboardCard>
    </>
  )
}

export { AccessKeys }
