import React, { useState } from 'react'
import {
  Grid,
  Flex,
  Text,
  Button,
  Divider,
  Modal,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import { format } from 'date-fns'
import { RouteComponentProps } from '@reach/router'
import { ChangePasswordForm } from './change-password'
import { ChangeUserInfoForm } from './change-user-info'
import { useUserService, userEvents } from '../../user/user.service'
import SEO from '../../../components/seo'
import { DashboardCard } from '../../../components/dashboard-card'

const Dashboard = (props: RouteComponentProps) => {
  const { user, isLoading, send } = useUserService()

  console.log({ user, props })

  const [modalContent, setModalContent] = useState()
  const { isOpen, onOpen, onClose } = useDisclosure()

  if (isLoading) {
    return null
  }

  const { email, name, remaining, limit, subscription } = user

  const openModal = content => {
    setModalContent(content)
    onOpen()
  }

  const showPasswordForm = modalContent === 'passwordForm'
  const showUserInfoForm = modalContent === 'userInfoForm'

  const handleUserFormSuccess = (ctx, evt) => {
    send(userEvents.CACHE_USER, { payload: evt.data.data })
    onClose()
  }

  return (
    <>
      <SEO title="Dashboard" />
      <DashboardCard title="Account">
        <Grid p="4" templateRows="4">
          <Grid templateColumns="10rem 1fr">
            <Text>Name:</Text>
            <Text>{name}</Text>
          </Grid>

          <Grid my="4" templateColumns="10rem 1fr">
            <Text>Email:</Text>
            <Text>{email}</Text>
          </Grid>

          <Divider my="4" />
          <Flex justifyContent="flex-end">
            <Button justifySelf="end" onClick={() => openModal('passwordForm')}>
              Change Password
            </Button>
            <Button
              justifySelf="end"
              ml="4"
              onClick={() => openModal('userInfoForm')}
            >
              Edit User Details
            </Button>
          </Flex>
        </Grid>
      </DashboardCard>

      <DashboardCard
        mt="6"
        subtitle={
          <>
            <Text as="span">
              {format(subscription.current_period_start * 1000, 'MM/dd/yyyy')}
            </Text>
            <Text as="span" fontWeight="bold" mx="2">
              -
            </Text>
            <Text as="span">
              {format(subscription.current_period_end * 1000, 'MM/dd/yyyy')}
            </Text>
          </>
        }
        title="Usage"
      >
        <Grid p="4" templateRows="4">
          Screenshots used: {limit - remaining} / {limit}
        </Grid>
      </DashboardCard>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        {showPasswordForm && <ChangePasswordForm onCancel={onClose} />}
        {showUserInfoForm && (
          <ChangeUserInfoForm
            onCancel={onClose}
            onSuccess={handleUserFormSuccess}
            user={user}
          />
        )}
      </Modal>
    </>
  )
}

export { Dashboard }
