import React from 'react'
import {
  Box,
  Button,
  Code,
  Divider,
  Flex,
  Heading,
  Link,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react'
import { useCopyToClipboard } from 'react-use'
import { Link as GatsbyLink } from 'gatsby'

import { FaCopy, FaExternalLinkAlt } from 'react-icons/fa'
import SEO from '../../components/seo'
import { DashboardCard } from '../../components/dashboard-card'
import { useUserService } from '../user/user.service'
import { get_valid_access_key } from '../../utils/helper-fns'
import config from '../../config'

const GettingStarted = () => {
  const { user, isLoading } = useUserService()
  const [, copyToClipboard] = useCopyToClipboard()
  const toast = useToast()

  if (isLoading) {
    return null
  }

  const key = get_valid_access_key(user?.accessKeys)
  const params = `&url=https://reddit.com`
  const screenshot_link = `${config.SCREENSHOT_ENDPOINT}/v1/screenshot?access_key=${key}${params}`

  return (
    <>
      <SEO title="Getting Started" />
      <DashboardCard title="Your first API call">
        <Heading mb="4" size="sm">
          Try a simple API call for your first screenshot:
        </Heading>
        <Code alignSelf="center" p="4">
          {config.SCREENSHOT_ENDPOINT}/v1/screenshot
          <Box pl="4">?access_key={key}</Box>
          <Box pl="4">{params}</Box>
        </Code>
        <Flex>
          <Button
            mr="4"
            mt="4"
            onClick={() => {
              copyToClipboard(screenshot_link)
              toast({
                title: 'Copied to clipboard!',
                status: 'success',
                duration: 5000,
                isClosable: true,
              })
            }}
            rightIcon={<FaCopy />}
          >
            Copy to Clipboard
          </Button>
          <Link
            alignItems="center"
            alignSelf="flex-end"
            display="flex"
            href={screenshot_link}
            isExternal
          >
            <Button rightIcon={<FaExternalLinkAlt />}>Take Screenshot</Button>
          </Link>
        </Flex>

        <Text justifySelf="center" my="6">
          Ready to use our
          <Link
            as={GatsbyLink}
            fontWeight="bold"
            ml="1"
            textDecoration="underline"
            to="/dashboard/screenshot-builder"
          >
            screenshot builder?
          </Link>
        </Text>

        <Divider />

        <Heading my="4" size="sm">
          Some helpful tips
        </Heading>

        <Stack as="ul" px="6">
          <Text as="li">
            Making an API call with the same parameters will return a cached
            screenshot. A cached screenshot does not count towards your monthly
            limit. In order to create an updated screenshot with the same
            parameters you must use the <Code>fresh</Code> parameter.
          </Text>

          <Text as="li">
            Our screenshot engine will wait until the network is idle. Sometimes
            you may want to delay a few seconds before taking your screenshot.
            Our parameter <Code>delay</Code> (in seconds and a limit of 10
            seconds) will delay taking the screenshot.
          </Text>

          <Text as="li">
            Visit our{' '}
            <Link
              as={GatsbyLink}
              textDecoration="underline"
              to="/documentation"
            >
              documentation
            </Link>{' '}
            for all available parameters and code examples.
          </Text>
          <Text as="li">
            Send us a{' '}
            <Link as={GatsbyLink} textDecoration="underline" to="/contact">
              message
            </Link>{' '}
            if you have any questions or comments. We&apos;re always looking to
            improve our service!
          </Text>
        </Stack>
      </DashboardCard>
    </>
  )
}

export { GettingStarted }
