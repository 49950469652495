export default [
  {
    title: 'Free',
    price: 0,
    reasons: ['100 Screenshots', 'Full Page Screenshots', 'Basic Support'],
    planId: __DEV__ ? 'price_HMrBMyw25GfZxV' : 'price_1Hjm6eHpbu7GduFk3qFT8mLP', // Free
  },
  {
    title: 'Basic',
    price: 6.5,
    reasons: [
      '1,000 Screenshots',
      'Full Page Screenshots',
      '4K + Retina Resolution',
      'Priority Support',
    ],
    planId: __DEV__
      ? 'price_1HgV44Hpbu7GduFkK7aGeyPB'
      : 'price_1Hjm6qHpbu7GduFkW8gnRQrI', // Basic v2
  },
  {
    title: 'Startup',
    price: 30,
    reasons: [
      '10,000 Screenshots',
      'Full Page Screenshots',
      '4K + Retina Resolution',
      'Priority Support',
    ],
    planId: __DEV__
      ? 'price_1HUcH4Hpbu7GduFkZyud9Iqf'
      : 'price_1Hjm6kHpbu7GduFkFb6Cgsk1', // Startup
  },
  {
    title: 'Business',
    price: 125,
    reasons: [
      '50,000 Screenshots',
      'Full Page Screenshots',
      '4K + Retina Resolution',
      'Priority Support',
    ],
    planId: __DEV__
      ? 'price_1HgV5kHpbu7GduFktuuLBRnn'
      : 'price_1Hjm6wHpbu7GduFkRlmYzKiW', // Business
  },
  {
    title: 'Enterprise',
    price: 175,
    reasons: [
      '100,000 Screenshots',
      'Full Page Screenshots',
      '4K + Retina Resolution',
      'Premium Support',
    ],
    planId: __DEV__ ? 'price_HMqAsfpwVv3k7D' : 'price_1Hjm6VHpbu7GduFkkSS5wdrh', // Enterprise
  },
]
