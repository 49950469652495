import React, { useState } from 'react'
import {
  Flex,
  Button,
  Text,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react'
import { Auth } from 'aws-amplify'
import { useForm } from 'react-hook-form'

const ChangePasswordForm = ({ onCancel }) => {
  const [amplifyError, setAmplifyError] = useState(null)

  const { register, handleSubmit, formState: { errors } } = useForm()

  const onSubmit = async values => {
    const { oldPassword, newPassword } = values

    try {
      const user = await Auth.currentAuthenticatedUser()
      await Auth.changePassword(user, oldPassword, newPassword)
      onCancel()
    } catch (error) {
      console.log('ChangePassword error', { error })
      setAmplifyError(error.message)
    }
  }

  return (
    <ModalContent>
      <ModalHeader>Change Password</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <FormControl
          as="form"
          isInvalid={Boolean(amplifyError)}
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormLabel htmlFor="oldPassword">Old Password</FormLabel>
          {errors.oldPassword && (
            <Text color="red.300" fontSize="xs">
              {errors.oldPassword}
            </Text>
          )}
          <Input
            mb={4}
            type="password"
            {...register('oldPassword', {
              required: 'Password required',
              minLength: {
                value: 6,
                message: 'Must be at least 6 characters'
              }
            })}
          />
          <FormLabel htmlFor="newPassword">New Password</FormLabel>
          {errors.newPassword && (
            <Text color="red.300" fontSize="xs">
              {errors.newPassword.message}
            </Text>
          )}
          <Input
            mb={4}
            type="password"
            {...register('newPassword', {
              required: 'Password required',
              minLength: {
                value: 6,
                message: 'Must be at least 6 characters'
              }
            })}
          />

          <FormErrorMessage>{amplifyError}</FormErrorMessage>

          <Flex justifyContent="flex-end" py="3">
            <Button onClick={onCancel} variant="ghost">
              Cancel
            </Button>
            <Button ml="4" type="submit">
              Confirm
            </Button>
          </Flex>
        </FormControl>
      </ModalBody>
    </ModalContent>
  )
}

export { ChangePasswordForm }
