import React from 'react'
import {
  Box,
  Button,
  Checkbox,
  Code,
  Divider,
  Flex,
  FormLabel,
  Grid,
  Input,
  Link,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react'
import { useCopyToClipboard } from 'react-use'
import { useFormik } from 'formik'
import { FaCopy, FaExternalLinkAlt } from 'react-icons/fa'
import config from '../../config'
import { useUserService } from '../user/user.service'
import SEO from '../../components/seo'
import { DashboardCard } from '../../components/dashboard-card'
import { get_valid_access_key } from '../../utils/helper-fns'

const InputContainer = ({ children }) => {
  return (
    <Grid alignItems="center" mt="4" templateColumns="7rem 25rem">
      {children}
    </Grid>
  )
}

const createScreenshotLink = (key, values) => {
  if (!values.url) return null

  const params = Object.keys(values)
    .map(param => {
      if (!values[param]) return null

      return `&${param}=${values[param].toString()}`
    })
    .join('')

  return `${config.SCREENSHOT_ENDPOINT}/v1/screenshot?access_key=${key}${params}`
}

type LabelProps = {
  htmlFor: string
  title: string
}

const Label = ({ htmlFor, title }: LabelProps) => {
  return (
    <FormLabel htmlFor={htmlFor} textAlign="right">
      {title}
    </FormLabel>
  )
}

const QueryHelper = () => {
  const { user, isLoading } = useUserService()
  const [, copyToClipboard] = useCopyToClipboard()
  const toast = useToast()

  const formik = useFormik({
    initialValues: {
      accept_language: '',
      accuracy: '',
      cookies: '',
      css: '',
      delay: '',
      fail_on_4xx: false,
      fail_on_5xx: false,
      force: false,
      format: 'jpeg',
      full_page: false,
      headers: '',
      height: '',
      js: '',
      proxy: '',
      latitude: '',
      longitude: '',
      quality: '',
      retina: false,
      selector: '',
      thumbnail_width: '',
      timeout: '',
      transparent: false,
      ttl: '',
      url: '',
      user_agent: '',
      wait_for_selector: '',
      wait_timeout: '',
      width: '',
    },
    onSubmit: values => {
      console.log({ values })
    },
  })

  if (isLoading) {
    return null
  }

  const key = get_valid_access_key(user?.accessKeys)

  const screenshot_link = createScreenshotLink(key, formik.values)

  return (
    <>
      <SEO title="Screenshot Helper" />
      <DashboardCard title="Screenshot Helper">
        <Grid gridGap="4" p="4" templateColumns="1fr 1fr" templateRows="auto">
          <Grid>
            <Text>Quick Options</Text>
            <Divider my={4} />

            <InputContainer>
              <Label htmlFor="url" title="URL" />
              <Input
                id="url"
                onChange={formik.handleChange}
                value={formik.values.url}
              />
            </InputContainer>

            <InputContainer>
              <Label htmlFor="delay" title="Delay" />
              <Input
                id="delay"
                max={29}
                min={1}
                onChange={formik.handleChange}
                type="number"
                value={formik.values.delay}
              />
            </InputContainer>

            <InputContainer>
              <Label htmlFor="wait_for_selector" title="Wait For Selector" />
              <Input
                id="wait_for_selector"
                onChange={formik.handleChange}
                value={formik.values.wait_for_selector}
              />
            </InputContainer>

            <InputContainer>
              <Label htmlFor="selector" title="Selector" />
              <Input
                id="selector"
                onChange={formik.handleChange}
                value={formik.values.selector}
              />
            </InputContainer>

            <InputContainer>
              <Label htmlFor="force" title="Fresh capture" />
              <Checkbox
                id="force"
                isChecked={formik.values.force}
                onChange={formik.handleChange}
              />
            </InputContainer>

            <InputContainer>
              <Label htmlFor="full_page" title="Full Page" />
              <Checkbox
                id="full_page"
                isChecked={formik.values.full_page}
                onChange={formik.handleChange}
              />
            </InputContainer>

            <InputContainer>
              <Label htmlFor="format" title="Format" />
              <RadioGroup
                isInline
                onChange={value => {
                  formik.setFieldValue('format', value)
                }}
                value={formik.values.format}
              >
                <Stack direction="row">
                  <Radio value="jpeg">JPEG</Radio>
                  <Radio value="png">PNG</Radio>
                </Stack>
              </RadioGroup>
            </InputContainer>

            <Text mt={6}>Image Options</Text>
            <Divider my={4} />

            <InputContainer>
              <Label htmlFor="width" title="Width" />
              <Input
                id="width"
                onChange={formik.handleChange}
                type="number"
                value={formik.values.width}
              />
            </InputContainer>

            <InputContainer>
              <Label htmlFor="height" title="Height" />
              <Input
                id="height"
                onChange={formik.handleChange}
                type="number"
                value={formik.values.height}
              />
            </InputContainer>

            <InputContainer>
              <Label htmlFor="quality" title="Quality" />
              <Input
                id="quality"
                max={100}
                min={0}
                onChange={formik.handleChange}
                type="number"
                value={formik.values.quality}
              />
            </InputContainer>

            <InputContainer>
              <Label htmlFor="transparent" title="Transparent" />
              <Checkbox
                id="transparent"
                isChecked={formik.values.transparent}
                onChange={formik.handleChange}
              />
            </InputContainer>

            <InputContainer>
              <Label htmlFor="thumbnail_width" title="Thumbnail Width" />
              <Input
                id="thumbnailWidth"
                onChange={formik.handleChange}
                type="number"
                value={formik.values.thumbnail_width}
              />
            </InputContainer>

            <InputContainer>
              <Label htmlFor="retina" title="Retina" />
              <Checkbox
                id="retina"
                isChecked={formik.values.retina}
                onChange={formik.handleChange}
              />
            </InputContainer>

            <Text mt={6}>Browser Options</Text>
            <Divider my={4} />

            <InputContainer>
              <Label htmlFor="accept_language" title="Accept Language" />
              <Input
                id="accept_language"
                onChange={formik.handleChange}
                value={formik.values.accept_language}
              />
            </InputContainer>

            <InputContainer>
              <Label htmlFor="user_agent" title="User Agent" />
              <Input
                id="user_agent"
                onChange={formik.handleChange}
                value={formik.values.user_agent}
              />
            </InputContainer>

            <InputContainer>
              <Label htmlFor="headers" title="Headers" />
              <Input
                id="headers"
                onChange={formik.handleChange}
                value={formik.values.headers}
              />
            </InputContainer>

            <InputContainer>
              <Label htmlFor="cookies" title="Cookies" />
              <Input
                id="cookies"
                onChange={formik.handleChange}
                value={formik.values.cookies}
              />
            </InputContainer>

            <InputContainer>
              <Label htmlFor="latitude" title="Latitude" />
              <Input
                id="latitude"
                onChange={formik.handleChange}
                type="number"
                value={formik.values.latitude}
              />
            </InputContainer>

            <InputContainer>
              <Label htmlFor="longitude" title="Longitude" />
              <Input
                id="longitude"
                onChange={formik.handleChange}
                type="number"
                value={formik.values.longitude}
              />
            </InputContainer>

            <InputContainer>
              <Label htmlFor="accuracy" title="Accuracy" />
              <Input
                id="accuracy"
                max={100}
                min={0}
                onChange={formik.handleChange}
                type="number"
                value={formik.values.accuracy}
              />
            </InputContainer>

            <InputContainer>
              <Label htmlFor="proxy" title="Proxy" />
              <Input
                id="proxy"
                onChange={formik.handleChange}
                value={formik.values.proxy}
              />
            </InputContainer>

            <Text mt={4}>Other</Text>
            <Divider my={4} />

            <InputContainer>
              <Label htmlFor="fail_on_4xx" title="Fail on 4xx" />
              <Checkbox
                id="fail_on_4xx"
                isChecked={formik.values.fail_on_4xx}
                onChange={formik.handleChange}
              />
            </InputContainer>

            <InputContainer>
              <Label htmlFor="fail_on_5xx" title="Fail on 5xx" />
              <Checkbox
                id="fail_on_5xx"
                isChecked={formik.values.fail_on_5xx}
                onChange={formik.handleChange}
              />
            </InputContainer>
          </Grid>
          <Flex flexDirection="column">
            <Code alignSelf="start" py="4">
              {config.SCREENSHOT_ENDPOINT}/v1/screenshot
              <Box pl="4">?access_key={key}</Box>
              {Object.keys(formik.values).map(param => {
                if (!formik.values[param]) return null

                return (
                  <Box key={param} pl="4">
                    &{param}={formik.values[param].toString()}
                  </Box>
                )
              })}
            </Code>
            <Flex>
              <Button
                isDisabled={!screenshot_link}
                mr="4"
                mt="4"
                onClick={() => {
                  copyToClipboard(screenshot_link)
                  toast({
                    title: 'Copied to clipboard!',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                  })
                }}
                rightIcon={<FaCopy />}
              >
                Copy to Clipboard
              </Button>
              <Link
                alignItems="center"
                alignSelf="flex-end"
                display="flex"
                href={screenshot_link}
                isDisabled={!screenshot_link}
                isExternal
                mt="4"
              >
                <Button
                  isDisabled={!screenshot_link}
                  mr="2"
                  rightIcon={<FaExternalLinkAlt />}
                >
                  Take Screenshot
                </Button>
              </Link>
            </Flex>
          </Flex>
        </Grid>
      </DashboardCard>
    </>
  )
}

export { QueryHelper }
