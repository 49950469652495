import { Machine, assign } from 'xstate'

const events = {
  FETCH: 'fetch',
  RETRY: 'retry',
}

const fetchOpts = {
  actions: {
    cacheFetched: assign((_, evt: any) => {
      return {
        data: evt.data?.data,
      }
    }),
    cacheError: assign((_, evt: any) => {

      if (__DEV__) {
        console.log({ fetch: evt })
      }


      const cognitoErr = evt.data?.message
      const error = evt.data?.response?.data?.error

      return {
        error: error || cognitoErr,
      }
    }),
    removeError: assign({ error: null }),
    // Client can add any action(s) when using
    onSuccess: () => {},
    onError: () => {},
  },
}

const fetchMachine = Machine<any>(
  {
    id: 'fetch',
    initial: 'idle',
    context: {
      data: undefined,
      error: undefined,
    },
    states: {
      idle: {
        on: { [events.FETCH]: 'loading' },
      },
      loading: {
        entry: 'removeError',
        invoke: {
          src: 'fetchData',
          onDone: {
            target: 'success',
            actions: ['cacheFetched', 'onSuccess'],
          },
          onError: {
            target: 'error',
            actions: ['cacheError', 'onError'],
          },
        },
      },
      success: {
        on: {
          [events.FETCH]: 'loading',
        },
      },
      error: {
        on: {
          [events.FETCH]: 'loading',
        },
      },
    },
  },
  fetchOpts
)

export { fetchMachine, events }
