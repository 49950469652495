import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import { useUserService } from '../features/user/user.service'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isInitialized, isAuthenticated } = useUserService()

  useEffect(() => {
    if (isInitialized && !isAuthenticated) {
      navigate('/login')
    }
  }, [isInitialized, isAuthenticated])

  return <Component {...rest} />
}

export { PrivateRoute }
