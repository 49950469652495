import React, { useState, useEffect } from 'react'
import {
  Flex,
  Grid,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
} from '@chakra-ui/react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { ChangeSubscriptionFree } from './change-subscription-free'
import { ChangeSubscriptionPaid } from './change-subscription-paid'
import config from '../../../config'
import SUBSCRIPTION_PLANS from '../../../utils/subscription-plans'
import { useUserService, userEvents } from '../../user/user.service'
import SEO from '../../../components/seo'
import { PlanCard } from '../../../components/plan-card'

const stripePromise = loadStripe(config.STRIPE_KEY)

const SubscriptionPlanView = props => {
  const { user, isLoading, isUpdating, send, success } = useUserService()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [currentPriceId, setCurrentPriceId] = useState('')
  const [changePlan, setChangePlan] = useState('')

  const handleModalClose = () => {
    console.log('called')
    setCurrentPriceId('')
    onClose()
  }

  useEffect(() => {
    if (success) {
      onClose()
    }
  }, [success])

  if (isLoading) {
    return null
  }

  const currentPlanId = user?.subscription?.planId

  const buttonText = idx => {
    const currentPlanIdx = SUBSCRIPTION_PLANS.findIndex(
      plan => plan.planId === currentPlanId
    )

    if (idx > currentPlanIdx) return 'Upgrade'

    return 'Downgrade'
  }

  const handleSubscribe = payload => {
    send({ type: userEvents.UPDATE_USER, payload })
  }

  return (
    <Flex>
      <SEO title="Change Subscription" />
      <Grid
        gridGap="5"
        maxWidth={['xl', '2xl', '4xl', '4xl']}
        templateColumns={['1fr', '1fr', '1fr 1fr', '1fr 1fr', 'repeat(3, 1fr)']}
        templateRows="1"
        width="100%"
      >
        {SUBSCRIPTION_PLANS.map((plan, idx) => {
          return (
            <PlanCard key={plan.planId} plan={plan}>
              {currentPlanId === plan.planId ? (
                <Flex
                  alignItems="center"
                  height="2.5rem"
                  justifyContent="center"
                >
                  <Text fontWeight="bold">Current Plan</Text>
                </Flex>
              ) : (
                  <Button
                    justifySelf="center"
                    onClick={() => {
                      const context = buttonText(idx)
                      setCurrentPriceId(plan.planId)

                      if (context === 'Upgrade') {
                        setChangePlan('upgrade')
                      } else {
                        setChangePlan('downgrade')
                      }

                      onOpen()
                    }}
                  >
                    {buttonText(idx)}
                  </Button>
                )}
            </PlanCard>
          )
        })}
      </Grid>
      <Modal isOpen={isOpen} onClose={handleModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Change your plan</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {changePlan === 'upgrade' ? (
              <Elements stripe={stripePromise}>
                <ChangeSubscriptionPaid
                  currentPriceId={currentPriceId}
                  isUpdating={isUpdating}
                  onCancel={handleModalClose}
                  onSubscribe={handleSubscribe}
                  user={user}
                />
              </Elements>
            ) : (
                <ChangeSubscriptionFree
                  currentPriceId={currentPriceId}
                  isUpdating={isUpdating}
                  onCancel={handleModalClose}
                  onSubscribe={handleSubscribe}
                  user={user}
                />
              )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  )
}

export { SubscriptionPlanView }
