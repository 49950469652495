import React from 'react'
import { Flex, Button, Link } from '@chakra-ui/react'
import { Link as GatsbyLink } from 'gatsby'

const _GatsbyLink = ({ href, variant, children, ...rest }) => {
  return (
    <Button as={GatsbyLink} to={href} variant={variant} {...rest}>
      {children}
    </Button>
  )
}

const _Link = ({ href, variant, children, ...rest }) => {
  return (
    <Button as={Link} href={href} variant={variant} {...rest}>
      {children}
    </Button>
  )
}

type SideNavLink = {
  title: string
  href: string
}

type SideNavProps = {
  currentRoute?: string
  links: SideNavLink[]
  isGatsbyLink?: boolean
}

export const SideNav = ({
  currentRoute,
  links,
  isGatsbyLink,
  ...rest
}: SideNavProps) => {
  const LinkComponent = isGatsbyLink ? _GatsbyLink : _Link

  return (
    <Flex flexDirection="column" mr={5} alignItems="flex-start" {...rest}>
      {links.map(link => {
        const variant = link.href === currentRoute ? 'solid' : 'ghost'

        return (
          <LinkComponent
            key={link.href}
            href={link.href}
            variant={variant}
            my="2"
          >
            {link.title}
          </LinkComponent>
        )
      })}
    </Flex>
  )
}
